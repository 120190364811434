import React from 'react'
import ContactFormModalFull from './ContactFormModalFull'
import ContactFormModalMobile from './ContactFormModalMobile'

function ContactFormModal({isOpen, onRequestClose, isMobile}) {
  const modal = isMobile ? 
    <ContactFormModalMobile isOpen={isOpen} onRequestClose={onRequestClose} /> :
    <ContactFormModalFull isOpen={isOpen} onRequestClose={onRequestClose} />

  return (
    <React.Fragment>
      {modal}
    </React.Fragment>
  )
}

export default ContactFormModal
