import React, { useEffect, useState } from 'react'

function TextInput({name, label, type, multiline, rows, handleChange}) {
  const [value, setValue] = useState('');

  useEffect(() => {
    handleChange(name, value);
  }, [value])

  const handleTextChange = (e) => {
    setValue(e.target.value)
  }

  if (multiline) {
    return (
      <div>
        <label style={styles.labelStyle}>{label}</label>
        <textarea
          style={styles.textBoxStyle} 
          name={name} 
          value={value}
          rows={rows ? rows : 8}
          onChange={handleTextChange}
        />
      </div>
    )
  } else {
    return (
      <div>
        <label style={styles.labelStyle}>{label}</label>
        <input 
          style={styles.textBoxStyle} 
          type={type ? type : 'text'} 
          name={name} 
          value={value} 
          onChange={handleTextChange}
        />
      </div>
    )
  }
}

const styles = {
  textBoxStyle: {
    padding: '12px 0px 12px 16px',
    fontSize: 16,
    margin: '8px 0',
    borderRadius: '36px',
    borderColor: '#f4b222',
    resize: 'vertical',
    outline: 'none',
    width: '100%',
    height: 'auto',
    fontFamily: 'sans-serif',
  },
  labelStyle: {
    color: '#f4b222',
    fontWeight: 800,
    fontSize: 18,
  },
}

export default TextInput
