import React, { useContext, useCallback } from 'react'
import { ThemeContext } from '../../../context/ThemeContext'
import ShondurasLogo from '../../../resources/svg/shonduras.svg'
import AwardBar from '../../award-bar/AwardBar'
import ContactModalButton from '../../buttons/ContactModalButton'
import RoundedButton from '../../buttons/RoundedButton'

function FrontBrandingMobile() {
  const themeContext = useContext(ThemeContext);
  const handleImageClick = useCallback(
    (external_url) => {
      window.open(external_url)
    },
    [],
  )
  return (
    <div style={styles.wrapper}>
      <div style={styles.brandImageContainer}>
        <img style={styles.brandImage} src={ShondurasLogo} alt={'Shonduras Branding'} />
      </div>
      <ContactModalButton fontSize={18} width={'160px'} />
      <div style={styles.buttonContainer}>
        <RoundedButton
          title={'MERCH'} 
          backgroundColor={themeContext.colors.gray}
          borderColor={themeContext.colors.yellow}
          width={'160px'}
          fontColor={'white'}
          underlayColor={themeContext.colors.yellow}
          underlayTextColor={'white'}
          fontSize={18}
          onClick={() => {
            handleImageClick('https://aforadley.com/shop/')
          }} 
        /> 
      </div>
      <AwardBar isMobile />
    </div>
  )
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    padding: '16px 0',
  },
  branding: {
    color: 'white',
    fontSize: 34,
  },
  brandImageContainer: {
    width: '220px',
    padding: '24px 0 24px 0',
  },
  brandImage: {
    width: '100%',
  },
}

export default FrontBrandingMobile
