import {createContext, useState, useEffect, useCallback} from 'react';

export const DimensionContext = createContext();

function DimensionContextProvider({children}) {
  const [dimensions, setDimensions] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
    isMobile: document.documentElement.clientWidth < 800 ? true : false,
  })

  const handleResize = useCallback(
    () => {
      setDimensions({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
        isMobile: document.documentElement.clientWidth < 800 ? true : false,
      })
    },
    [setDimensions],
  )

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => { 
      window.removeEventListener('resize', handleResize) 
    }
  }, [handleResize])

  return (
    <DimensionContext.Provider value={dimensions}>
      {children}
    </DimensionContext.Provider>
  )
}

export default DimensionContextProvider;
