import React from 'react';
import BackgroundImage from '../../components/layout/BackgroundImage';
import FrontBranding from '../../components/home-page/front-branding/FrontBranding';
import BGImage from '../../resources/images/Shonduras_BG_Web_High.jpg';

function HomePageFull() {
  return (
    <React.Fragment>
      <BackgroundImage url={BGImage}>
        <div style={styles.wrapper}>
          <FrontBranding />
        </div> 
      </BackgroundImage>
    </React.Fragment>
  )
}

const styles = {
  wrapper: {
    display: 'flex',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  }
}

export default HomePageFull
