import React, { useContext } from 'react'
import ReactModal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import ContactForm from './contact-form/ContactForm'
import { ContactModalContext } from '../../context/ContactModalContext'

function ContactFormModalMobile({isOpen, onRequestClose}) {
  const contactContext = useContext(ContactModalContext);

  const onSubmitWithReCAPTCHA = async () => {
    await contactContext.functions.sendMail();
  }


  const handleValueChange = value => {
  }

  return (
    <ReactModal
      isOpen={isOpen} 
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      closeTimeoutMS={200}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: '#22201fee',
        },
        content: {
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '90%',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          border: '0px',
          background: 'transparent',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          outline: 'none',
        }
      }}
    >
      <div style={styles.exitButton} onClick={onRequestClose}>
        <FontAwesomeIcon color={'white'} icon={faTimes} />
      </div>
      <div style={styles.innerContainer} >
        <ContactForm
          handleValueChange={handleValueChange}
          reCaptchaSubmit={onSubmitWithReCAPTCHA}
        />
      </div>
    </ReactModal>
  )
}

const styles = {
  exitButton: {
    position: 'absolute',
    color: 'white',
    right: 0,
    margin: '24px 36px 0 0',
    cursor: 'pointer',
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#22201f',
    borderRadius: '36px',
    color: 'blue',
    padding: '48px 0px',
    width: '100%',
  }
}

export default ContactFormModalMobile
