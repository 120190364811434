import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import HomePage from './pages/home-page/HomePage'

function AppNavigator() {
  return (
    <Router>
      <Switch>
        <Route path={"/"}>
          <HomePage />
        </Route>
      </Switch>
    </Router>
  )
}

export default AppNavigator
