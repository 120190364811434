import React, { useState } from 'react'

function RoundedButton({title, backgroundColor, borderColor, width, fontSize, fontColor, onClick, disabled, underlayColor}) {
  const [hovered, setHovered] = useState(false);
  let newStyle = {
    ...styles.container,
    border: `2px solid ${borderColor}`,
    backgroundColor: hovered ? underlayColor : backgroundColor,
    width,
    opacity: disabled ? '0.6' : '1',
  }

  const textStyle = {
    ...styles.title,
    fontSize,
    color: fontColor,
  }
  
  return (
    <div 
      style={newStyle} 
      onClick={disabled ? () => {} : onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <span style={textStyle}>{title}</span>
    </div>
  )
}

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    padding: '16px 24px',
    backgroundColor: 'white',
    borderRadius: '36px',
    cursor: 'pointer',
    zIndex: 1000,
    transition: 'all 0.3s',
  },
  title: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
  }
}

export default RoundedButton
