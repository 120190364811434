import React, { useCallback, useContext } from 'react'
import AwardBar from '../award-bar/AwardBar'
import PartnerBar from '../partner-bar/PartnerBar'
import ForegroundAnimation from './ForegroundAnimation'

import SatteliteLottie from '../../resources/svg-animations/satellite.json';
import MoonLottie from '../../resources/svg-animations/moon_orbit.json';
import RocketLottie from '../../resources/svg-animations/rocket1.json'
import PirateShipLottie from '../../resources/svg-animations/pirateship-flag.json';
import TreasureLottie from '../../resources/svg-animations/treasure.json';
import StarTwinkleLottie from '../../resources/svg-animations/star_yellowspin.json'
import {ThemeContext} from '../../context/ThemeContext';

function ForegroundLayerFull() {
  const themeContext = useContext(ThemeContext);
  const handleImageClick = useCallback(
    (external_url) => {
      window.open(external_url)
    },
    [],
  )

  return (
    <div style={styles.container}>
      <ForegroundAnimation 
        src={SatteliteLottie}
        styling={{
          left: '72px',
          top: '64px',
          width: '10%',
        }}
        onClick={() => {
          handleImageClick('https://spacestation.com/');
        }}
      />
      <ForegroundAnimation 
        src={MoonLottie}
        styling={{
          right: '7.5%',
          top: '40px',
          width: '10%',
        }}
        onClick={() => {
          handleImageClick('https://spacestationgaming.com/');
        }}
      />
      <ForegroundAnimation 
        src={RocketLottie}
        onClick={() => handleImageClick('https://spacestationintegrations.com/')}
        styling={{
          left: '15%',
          bottom: '16%',
          width: '14%',
        }}
      />
      <ForegroundAnimation 
        src={PirateShipLottie}
        onClick={() => handleImageClick('https://www.youtube.com/user/Shonduras')}
        styling={{
          right: '17%',
          bottom: '20%',
          width: '10%',
        }}
      />
      <ForegroundAnimation 
        src={TreasureLottie}
        onClick={() => handleImageClick('https://aforadley.com/shop/')}
        styling={{
          left: '51%',
          bottom: '8%',
          width: '10%',
        }}
      />
      <ForegroundAnimation 
        src={StarTwinkleLottie}
        styling={{
          left: '48%',
          top: '64px',
          width: '3%',
        }}
        autoplay
        loop
      />
      <ForegroundAnimation 
        src={StarTwinkleLottie}
        styling={{
          left: '56%',
          top: '36px',
          width: '1%',
        }}
        autoplay
        loop
      />
      <ForegroundAnimation 
        src={StarTwinkleLottie}
        styling={{
          left: '35%',
          top: '36px',
          width: '2%',
        }}
        autoplay
        loop
      />
      <ForegroundAnimation 
        src={StarTwinkleLottie}
        styling={{
          left: '5%',
          top: '64px',
          width: '1.5%',
        }}
        autoplay
        loop
      />
      <ForegroundAnimation 
        src={StarTwinkleLottie}
        styling={{
          left: '15%',
          top: '28%',
          width: '1.5%',
        }}
        autoplay
        loop
      />
      <ForegroundAnimation 
        src={StarTwinkleLottie}
        styling={{
          left: '8%',
          top: '60%',
          width: '2.5%',
        }}
        autoplay
        loop
      />
      <ForegroundAnimation 
        src={StarTwinkleLottie}
        styling={{
          right: '15%',
          top: '40%',
          width: '3%',
        }}
        autoplay
        loop
      />
      <ForegroundAnimation 
        src={StarTwinkleLottie}
        styling={{
          right: '17%',
          top: '30%',
          width: '1.5%',
        }}
        autoplay
        loop
      />
      <div style={{...styles.awardContainer, backgroundColor: 'transparent'}}>
        <AwardBar />
      </div>
      <div style={{...styles.partnerContainer, backgroundColor: 'transparent'}}>
        <PartnerBar />
      </div>
    </div>
  )
}

const styles = {
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    zIndex: 1,
  },
  awardContainer: {
    position: 'absolute',
    right: '0',
    bottom: '0',
    margin: '0 48px 8px 0',
    width: '24%',
    zIndex: 5,
  },
  partnerContainer: {
    position: 'absolute',
    left: '0',
    bottom: '0',
    margin: '0 0 8px 48px',
    width: '24%',
    zIndex: 5,
  },
}

export default ForegroundLayerFull
