import React, { useCallback, useContext, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import LoadingLottie from '../../../resources/animations/yellow_loading.json';
import MailSentLottie from '../../../resources/animations/mail_sent.json';
import { ThemeContext } from '../../../context/ThemeContext';
import RoundedButton from '../../buttons/RoundedButton';
import DropdownInput from '../../text-input/DropdownInput';
import TextInput from '../../text-input/TextInput';
import { ContactModalContext } from '../../../context/ContactModalContext';

const personTypeOptions = [
  {
    title: 'Brand',
    value: 'brand',
  },
  {
    title: 'Creator',
    value: 'creator',
  },
  {
    title: 'Founder',
    value: 'founder',
  },
  {
    title: 'Friend',
    value: 'friend',
  },
]

const defaultLoadingOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const defaultMailSentOptions = {
  loop: true,
  autoplay: true,
  animationData: MailSentLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

function ContactForm({width, fontSize, reCaptchaSubmit, loading}) {
  const themeContext = useContext(ThemeContext);
  const contactContext = useContext(ContactModalContext);
  const [formValues, setFormValues] = useState({
    name: '',
    person: personTypeOptions[0].value,
    email: '',
    subject: '',
    message: '',
  });

  useEffect(() => {
    contactContext.setFormValues(formValues);
  },[formValues]);

  const handleChange = useCallback(
    (name ,value) => {
      setFormValues({
        ...formValues,
        [name]: value,
      })
    },
    [formValues],
  )

  if (contactContext.loading === 'loading') {
    return (
      <div style={styles.formWrapper}>
        <Lottie 
          options={defaultLoadingOptions}
          height={200}
          width={200}
        />
      </div>
    )
  } else if (contactContext.loading === 'success') {
    return (
      <div style={styles.formWrapper}>
        <div style={styles.successBox}>
          Your message has been sent!
        </div>
        <div style={styles.lottieContainer}>
          <Lottie 
            options={defaultMailSentOptions}
            height={100}
            width={100}
          />
        </div>
      </div>
    )
  }
  return (
    <div style={styles.formWrapper}>
      <div style={styles.textboxWrapper}>
        <TextInput name={'name'} label={'Name'} handleChange={handleChange} />
      </div>
      <div style={styles.textboxWrapper}>
        <DropdownInput name={'person'} label={'Who are you?'} options={personTypeOptions} handleChange={handleChange} />
      </div>
      <div style={styles.textboxWrapper}>
        <TextInput name={'email'} label={'Email'} type={'email'} handleChange={handleChange} />
      </div>
      <div style={styles.textboxWrapper}>
        <TextInput name={'subject'} label={'Subject'} type={'text'} handleChange={handleChange} />
      </div>
      <div style={styles.textboxWrapper}>
        <TextInput name={'message'} label={"Let's Talk!"} multiline rows={8} handleChange={handleChange} />
      </div>
      <div style={{display: 'flex', justifyContent: 'center', padding: '16px 0'}}>
        <RoundedButton 
          title={'SUBMIT'}
          disabled={contactContext.loading}
          fontColor={'white'} 
          onClick={reCaptchaSubmit ? reCaptchaSubmit : () => {console.log('Submit')}}
          backgroundColor={themeContext.colors.yellow}
          width={'200px'}
          fontSize={fontSize ? fontSize : 24}
        />
      </div>
    </div>
  )
}

const styles = {
  formWrapper: {
    width: '70%',
    padding: '32px 0',
  },
  textboxWrapper: {
    padding: '8px 0px',
  },
  successBox: {
    textAlign: 'center',
    color: 'white',
    fontFamily: 'sans-serif',
    fontSize: 24,
  },
  lottieContainer: {
    padding: '64px 0',
  },
}

export default ContactForm
