import React, { useCallback, useContext } from 'react'
import { ThemeContext } from '../../../context/ThemeContext';
import ShondurasLogo from '../../../resources/svg/shonduras.svg'
import ContactModalButton from '../../buttons/ContactModalButton'
import RoundedButton from '../../buttons/RoundedButton'

function FrontBrandingFull() {
  const themeContext = useContext(ThemeContext);
  const handleImageClick = useCallback(
    (external_url) => {
      window.open(external_url)
    },
    [],
  )

  return (
    <div>
      <div style={styles.wrapper}>
        <div style={styles.brandImageContainer}>
          <img style={styles.brandImage} src={ShondurasLogo} alt={'Shonduras Branding'} />
        </div>
        <div style={styles.buttonrow}>
          <div style={styles.buttonContainer}>
            <ContactModalButton />
          </div>
          <div style={styles.buttonContainer}>
            <RoundedButton
              title={'MERCH'} 
              backgroundColor={themeContext.colors.gray}
              borderColor={themeContext.colors.yellow}
              width={'200px'}
              fontColor={'white'}
              underlayColor={themeContext.colors.yellow}
              underlayTextColor={'white'}
              fontSize={24}
              onClick={() => {handleImageClick('https://aforadley.com/shop/')}} 
            /> 
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  branding: {
    color: 'white',
    fontSize: 34,
  },
  brandImageContainer: {
    width: '450px',
    padding: '0 0 64px 0',
  },
  brandImage: {
    width: '100%',
  },
  buttonrow: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttonContainer: {
    padding: '0px 24px',
  },
}

export default FrontBrandingFull
