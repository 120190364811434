import Axios from "axios";
import { createContext, useCallback, useEffect, useState } from "react";

export const ContactModalContext = createContext();

function ContactModalContextProvider({children}) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState({
    name: false,
    person: false,
    email: false,
    subject: false,
    message: false,
  });
  const [formValues, setFormValues] = useState({
    name: '',
    person: '',
    email: '',
    subject: '',
    message: '',
  });

  useEffect(() => {
    const {name, person, email, subject, message} = formValues;
    let updateValues = {
      name: false,
      person: false,
      email: false,
      subject: false,
      message: false,
    }
    if (name.length > 0) {
      updateValues.name = true;
    }
    if (person.length > 0) {
      updateValues.person = true;
    }
    if (email.length > 0) {
      updateValues.email = true;
    }
    if (subject.length > 0) {
      updateValues.subject = true;
    }
    if (message.length > 0) {
      updateValues.message = true;
    }

    setValid(updateValues);
  }, [formValues])

  const verifyCaptcha = useCallback(async (token) => {
    const captchaReturn = await Axios({
      method: 'POST',
      url: 'https://us-central1-shonduras-website.cloudfunctions.net/verifyCaptcha',
      data: {
        token,
      }
    })

    return captchaReturn;
  },[])

  const sendMail = useCallback(async (token) => {
    setLoading('loading');
    if (token) {
      const captchaSuccess = await verifyCaptcha(token);
      if (captchaSuccess) {
        console.log('CAPTCHA SUCCESS!');
      } else {
        console.warn('CAPTCHA FAILED');
        setLoading(false);
        return false;
      }
    }

    const mailSuccess = await Axios({
      method: 'POST',
      url: 'https://us-central1-shonduras-website.cloudfunctions.net/sendShondurasMail',
      data: {
        ...formValues,
      }
    });

    setLoading('success');
    return mailSuccess;
  },[formValues, verifyCaptcha]);

  return (
    <ContactModalContext.Provider 
      value={
        {
          isOpen, 
          setIsOpen,
          formValues,
          setFormValues,
          loading,
          valid,
          functions: {
            sendMail,
            verifyCaptcha,
          }
        }
      }>
      {children}
    </ContactModalContext.Provider>
  )
}

export default ContactModalContextProvider;