import React, { useContext } from 'react'
import { DimensionContext } from '../../context/DimensionContext'
import ForegroundLayerFull from './ForegroundLayerFull'
import ForegroundLayerMobile from './ForegroundLayerMobile';

function ForegroundLayer() {
  const dimensionContext = useContext(DimensionContext);
  const {isMobile} = dimensionContext;

  return (
    isMobile ? <ForegroundLayerMobile /> : <ForegroundLayerFull />
  )
}

export default ForegroundLayer
