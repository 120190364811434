import './App.css';
import AppNavigator from './AppNavigator';
import DimensionContextProvider from './context/DimensionContext';
import ThemeContextProvider from './context/ThemeContext';
import ContactModalContextProvider from './context/ContactModalContext';

function App() {
  return (
    <ThemeContextProvider>
      <DimensionContextProvider>
      <ContactModalContextProvider>
        <div className="App">
          <AppNavigator />
        </div>
        </ContactModalContextProvider>
      </DimensionContextProvider>
    </ThemeContextProvider>
  );
}

export default App;
