import React, { useCallback } from 'react';
import SSGSVG from '../../resources/svg/ssg.svg';
import IntegrationsSVG from '../../resources/svg/integrations.svg';

function PartnerBar({isMobile}) {
  const handleImageClick = useCallback(
    (external_url) => {
      window.open(external_url)
    },
    [],
  )

  if (isMobile) {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <img 
          style={styles.imageContainer} 
          src={SSGSVG} alt={'SSG Branding'} 
          onClick={() => handleImageClick('https://spacestationgaming.com/')} 
        />
        <img 
          style={styles.imageContainer} 
          src={IntegrationsSVG} 
          alt={'Integrations Branding'} 
          onClick={() => handleImageClick('https://spacestationintegrations.com/')}
        />
      </div>
    )
  } else {
    return (
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <img 
          style={styles.imageContainer} 
          src={SSGSVG} alt={'SSG Branding'} 
          onClick={() => handleImageClick('https://spacestationgaming.com/')} 
        />
        <img 
          style={styles.imageContainer} 
          src={IntegrationsSVG} 
          alt={'Integrations Branding'} 
          onClick={() => handleImageClick('https://spacestationintegrations.com/')} 
        />
      </div>
    )
  }
  
}

const styles = {
  imageContainer: {
    width: '50%',
    maxHeight: '128px',
    padding: '24px 8px',
    cursor: 'pointer',
  },
  imageContainer2: {
    width: '33%',
    maxHeight: '96px',
    padding: '24px 8px',
  }
}

export default PartnerBar
