import React from 'react'

function ForegroundImage({styling, src, alt, onClick}) {
  const newStyle = {
    ...styles.imgContainer,
    ...styling, 
    cursor: onClick ? 'pointer' : 'default',
  }

  return (
    <div style={newStyle} onClick={onClick}>
      <img src={src} alt={alt} />
    </div>
  )
}

const styles = {
  imgContainer: {
    position: 'absolute',
    width: '128px',
    zIndex: 5,
  },
}

export default ForegroundImage
