import React, { useCallback, useContext } from 'react'
import { ContactModalContext } from '../../context/ContactModalContext';
import { ThemeContext } from '../../context/ThemeContext';
import RoundedButton from './RoundedButton'

function ContactModalButton({width, fontSize}) {
  const themeContext = useContext(ThemeContext);
  const modalContext = useContext(ContactModalContext);

  const handlePress = useCallback(
    () => {
      modalContext.setIsOpen(true);
    },
    [modalContext],
  )

  return (
    <RoundedButton 
      title={'CONTACT US'} 
      borderColor={themeContext.colors.yellow}
      backgroundColor={themeContext.colors.yellow}
      underlayColor={themeContext.colors.gray}
      width={width ? width : '200px'}
      fontColor={'white'}
      fontSize={fontSize ? fontSize : 24}
      onClick={handlePress} 
    /> 
  )
}

export default ContactModalButton
