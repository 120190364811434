import React from 'react'
import ForegroundLayer from '../foreground/ForegroundLayer'

function BackgroundImage({url, children, isMobile}) {
  if (isMobile) {
    const mobileStyle = {
      ...styles.mobileContainer,
      backgroundColor: '#212020',
      backgroundImage: `url(${url})`,
    }
    return (
      <div style={mobileStyle}>
      <ForegroundLayer />
          {children}
      </div>
    ) 
  } else {
    const webStyle = {
      ...styles.container,
      backgroundColor: '#212020',
      backgroundImage: `url(${url})`,
    }
    return (
      <div style={webStyle}>
        <ForegroundLayer />
          {children}
      </div>
    )
  }
}

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    minWidth: '1080px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    zIndex: 0,
  },
  mobileContainer: {
    position: 'relative',
    width: 'auto',
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    zIndex: 0,
  }
}

export default BackgroundImage
