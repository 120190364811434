import React from 'react';
import StreamyAwards from '../../resources/svg/streamy_awards.svg';
import TedX from '../../resources/svg/tedx.svg';
import Forbes30 from '../../resources/svg/forbes30.svg';

function AwardBar({isMobile}) {
  if (isMobile) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <img style={styles.imageContainer} src={StreamyAwards} alt={'Streamy Awards'} />
        <img style={styles.imageContainer} src={TedX} alt={'TedX'} />
        <img style={styles.imageContainer2} src={Forbes30} alt={'Forbes30'} />
      </div>
    )
  } else {
    return (
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <img style={styles.imageContainer} src={StreamyAwards} alt={'Streamy Awards'} />
        <img style={styles.imageContainer} src={TedX} alt={'TedX'} />
        <img style={styles.imageContainer2} src={Forbes30} alt={'Forbes30'} />
      </div>
    )
  }
}

const styles = {
  imageContainer: {
    width: '33%',
    maxHeight: '128px',
    padding: '24px 8px',
  },
  imageContainer2: {
    width: '33%',
    maxHeight: '96px',
    padding: '24px 8px',
  }
}

export default AwardBar
