import React, { useEffect, useState } from 'react'

function DropdownInput({name, label, options, handleChange}) {
  const [value, setValue] = useState(options[0].value);

  useEffect(() => {
    handleChange(name, value);
  }, [value])

  const handleTextChange = (e) => {
    setValue(e.target.value)
  }

  const selectOptions = options.map((item, i) => {
    return (
      <option key={i} value={item.value}>{item.title}</option>
    )
  })

  return (
    <div>
      <label style={styles.labelStyle}>{label}</label>
      <select style={styles.textBoxStyle} value={value} name={name} onChange={handleTextChange}>
        {selectOptions}
      </select>
    </div>
  )
}

const styles = {
  textBoxStyle: {
    padding: '12px 16px',
    fontSize: 16,
    margin: '8px 0',
    borderRadius: '36px',
    borderColor: '#f4b222',
    resize: 'vertical',
    outline: 'none',
    width: 'calc(100% + 18px)',
  },
  labelStyle: {
    color: '#f4b222',
    fontWeight: 800,
    fontSize: 18,
  },
}

export default DropdownInput
