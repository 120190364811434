import React, { useContext, useState } from 'react'
import ReactModal from 'react-modal'
import ReCAPTCHA from 'react-google-recaptcha'
import ShondurasBrand from '../../resources/svg/shonduras.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import ContactForm from './contact-form/ContactForm'
import { ContactModalContext } from '../../context/ContactModalContext'

function ContactFormModalFull({isOpen, onRequestClose}) {
  const recaptchaRef = React.useRef();
  const contactContext = useContext(ContactModalContext);

  const onSubmitWithReCAPTCHA = async () => {
    const token = await recaptchaRef.current.executeAsync();

    await contactContext.functions.sendMail(token);
  }

  const onChange = (value) => {
  }

  const handleValueChange = value => {
  }

  return (
    <ReactModal
      isOpen={isOpen} 
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      closeTimeoutMS={200}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: '#22201fee',
        },
        content: {
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '90%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          border: '0px',
          background: 'transparent',
          overflow: 'unset',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '0px',
        }
      }}
    >
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6Le05OYZAAAAAFGPMcesChkbX_awnrR6u3EFrJSd"
        size={'invisible'}
        badge={'bottomright'}
        onChange={onChange}
      />
      <div style={styles.innerContainer} >
        <div style={styles.exitButton} onClick={onRequestClose}>
          <FontAwesomeIcon color={'white'} icon={faTimes} />
        </div>
        <ContactForm
          handleValueChange={handleValueChange}
          reCaptchaSubmit={onSubmitWithReCAPTCHA}
        />
      </div>
    </ReactModal>
  )
}

const styles = {
  exitButton: {
    position: 'absolute',
    color: 'white',
    right: 0,
    top: 0,
    margin: '24px 36px 0 0',
    cursor: 'pointer',
  },
  imgContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 5,
    margin: '144px 0px',
  },
  imgBranding: {
    width: '100%',
  },
  innerContainer: {
    position: 'relative',
    width: '50%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#22201f',
    border: '4px solid',
    borderColor: '#f4b222',
    borderRadius: '36px',
    color: 'blue',
  }
}

export default ContactFormModalFull
