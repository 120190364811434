import React from 'react';
import BackgroundImage from '../../components/layout/BackgroundImage';
import ReactModal from 'react-modal';
import FrontBranding from '../../components/home-page/front-branding/FrontBranding';
import BGImage from '../../resources/images/Shonduras_BG_Mobile_Hi_Res.jpg';

function HomePageMobile({openContact ,setOpenContact}) {
  return (
    <BackgroundImage url={BGImage} isMobile>
      <div style={styles.wrapper}>
        <FrontBranding />
      </div>
      <ReactModal 
        isOpen={openContact} 
        onRequestClose={() => setOpenContact(false)}
        ariaHideApp={false}
        >
      </ReactModal>      
    </BackgroundImage>
  )
}

const styles = {
  wrapper: {
    position: 'absolute',
    top: '16%',
    left: '50%',
    transform: 'translate(-50%, 0)',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 5,
  }
}

export default HomePageMobile
