import React, { useCallback, useContext } from 'react';
import ContactFormModal from '../../components/contact-form-modal/ContactFormModal';
import { ContactModalContext } from '../../context/ContactModalContext';
import { DimensionContext } from '../../context/DimensionContext';
import HomePageFull from './HomePageFull';
import HomePageMobile from './HomePageMobile';

function HomePage() {
  const windowDimensions = useContext(DimensionContext);
  const modalContext = useContext(ContactModalContext);
  const {isOpen, setIsOpen} = modalContext;

  const handleClose = useCallback(
    () => {
      setIsOpen(false)
    },
    [setIsOpen],
  )

  return (
    <React.Fragment>
      {
        windowDimensions.isMobile ? 
          <HomePageMobile /> : 
          <HomePageFull />
      }
      <ContactFormModal 
        isOpen={isOpen} 
        onRequestClose={handleClose} 
        isMobile={windowDimensions.isMobile} 
      />
    </React.Fragment>
  )
}

export default HomePage
