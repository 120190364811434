import React, { useCallback } from 'react'
import SatteliteSVG from '../../resources/svg/spacestation.svg'
import MoonSVG from '../../resources/svg/planet.svg'
import RocketSVG from '../../resources/svg/rocket_with_launch.svg'
import PirateShipSVG from '../../resources/svg/pirate_ship.svg'
import TreasureChestSVG from '../../resources/svg/treasure_chest.svg'
import ForegroundImage from './ForegroundImage'
import ForegroundAnimation from './ForegroundAnimation'

import StarTwinkleLottie from '../../resources/svg-animations/star_yellowspin.json'

function ForegroundLayerMobile() {
  const handleImageClick = useCallback(
    (external_url) => {
      window.open(external_url)
    },
    [],
  )
  return (
    <div style={styles.container}>
      <ForegroundImage 
        src={SatteliteSVG}
        alt={'Sattelite'}
        onClick={() => {handleImageClick('https://spacestation.com/')}}
        styling={{
          left: '24px',
          top: '40px',
          width: '18%',
        }}
      />
      <ForegroundImage 
        src={MoonSVG}
        alt={'Moon'}
        onClick={() => {handleImageClick('https://spacestationgaming.com/')}}
        styling={{
          right: '24px',
          top: '40px',
          width: '17%',
        }}
      />
      <ForegroundImage 
        src={RocketSVG}
        alt={'Rocket'}
        onClick={() => handleImageClick('https://spacestationintegrations.com/')}
        styling={{
          left: '4%',
          bottom: '8%',
          width: '30%',
        }}
      />
      <ForegroundImage 
        src={PirateShipSVG}
        alt={'Pirate Ship'}
        onClick={() => handleImageClick('https://www.youtube.com/user/Shonduras')}
        styling={{
          right: '10%',
          bottom: '14%',
          width: '20%',
        }}
      />
      <ForegroundImage 
        src={TreasureChestSVG}
        alt={'Treasure Chest'}
        onClick={() => handleImageClick('https://aforadley.com/shop/')}
        styling={{
          left: '50%',
          transform: 'translate(-50%, 0)',
          bottom: '2%',
          width: '25%',
        }}
      />
      <ForegroundAnimation 
        src={StarTwinkleLottie}
        styling={{
          left: '40%',
          top: '8%',
          width: '8%',
        }}
        autoplay
        loop
      />
      <ForegroundAnimation 
        src={StarTwinkleLottie}
        styling={{
          left: '55%',
          top: '2.5%',
          width: '10%',
        }}
        autoplay
        loop
      />
      <ForegroundAnimation 
        src={StarTwinkleLottie}
        styling={{
          left: '55%',
          top: '75%',
          width: '10%',
        }}
        autoplay
        loop
      />
      <ForegroundAnimation 
        src={StarTwinkleLottie}
        styling={{
          left: '5%',
          top: '80%',
          width: '8%',
        }}
        autoplay
        loop
      />
    </div>
  )
}

const styles = {
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    zIndex: 1,
  },
  awardContainer: {
    position: 'absolute',
    right: '0',
    bottom: '0',
    margin: '0 48px 8px 0',
    width: '100px',
    zIndex: 5,
  },
  partnerContainer: {
    position: 'absolute',
    left: '0',
    bottom: '0',
    margin: '0 0 8px 48px',
    width: '100px',
    zIndex: 5,
  },
}

export default ForegroundLayerMobile
