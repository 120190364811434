import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'

function ForegroundAnimation({styling, src, autoplay, loop, onClick}) {
  const [play, setPlay] = useState(false);

  useEffect(() => {
    if (autoplay) {
      setTimeout(() => {
        setPlay(true);
      }, 500)
    }
  }, [])

  const newStyle = {
    ...styles.imgContainer,
    ...styling, 
    cursor: onClick ? 'pointer' : 'default',
  }

  const defaultLoadingOptions = {
    loop: loop ? loop : true,
    autoplay,
    animationData: src,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  if (onClick) {
    return (
      <div 
        style={newStyle} 
        onClick={onClick}
        onMouseOver={() => setPlay(true)}
        onMouseLeave={() => setPlay(false)}
      >
        <Lottie 
          options={defaultLoadingOptions}
          width={'100%'}
          height={'100%'}
          isPaused={!play}
        />
      </div>
    )
  } else {
    return (
      <div style={newStyle}>
        <Lottie 
          options={defaultLoadingOptions}
          width={'100%'}
          height={'100%'}
          isPaused={!play}
        />
      </div>
    )
  }
  
}

const styles = {
  imgContainer: {
    position: 'absolute',
    width: '128px',
    zIndex: 5,
  },
}

export default ForegroundAnimation;
