import React, { useContext } from 'react'
import { DimensionContext } from '../../../context/DimensionContext'

import FrontBrandingFull from './FrontBrandingFull'
import FrontBrandingMobile from './FrontBrandingMobile'

function FrontBranding() {
  const dimContext = useContext(DimensionContext);

  return (
    dimContext.isMobile ? <FrontBrandingMobile /> : <FrontBrandingFull />
  )
}

export default FrontBranding
