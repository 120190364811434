import { createContext, useState } from "react";

export const ThemeContext = createContext();

function ThemeContextProvider({children}) {
  const [colors] = useState({
    gray: '#22201f',
    yellow: '#f4b222',
  });

  return (
    <ThemeContext.Provider value={{colors}}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContextProvider;